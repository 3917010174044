<template>
  <div class="regionalSchool">
    <div class="top-main">
      <!-- 筛选框 -->
      <el-form label-width="auto" class="demo-ruleForm">
        <el-form-item :label="$t('el.planCourse.school')" prop="pass">
          <el-input
            type="text"
            size="small"
            disabled
            v-model="activeSchoolName"
          ></el-input>
        </el-form-item>
      </el-form>
      <p class="tip-text">{{ $t("el.planCourse.DataRefreshPrompt") }}</p>
    </div>
    <!-- 概览 -->
    <div class="overview">
      <p>学校基本信息</p>
      <ul v-loading="topLoading" class="overview-list">
        <li v-for="(item, index) in overviewaaList" :key="index" style="">
          <div class="item-bottom">
            <p>{{ item.title }}</p>
            <el-tooltip placement="top">
              <div
                style="width: 200px; line-height: 18px"
                class="message-content"
                slot="content"
              >
                {{ item.message }}
              </div>
              <i class="el-icon-question"></i>
            </el-tooltip>
          </div>
          <p class="item-top">{{ overviewaaData[item.label] }}</p>
          <img class="img" :src="item.imgUrl" alt="" />
        </li>
      </ul>
    </div>
    <!-- 表格 -->
    <div class="main-content">
      <p>{{ $t("el.planCourse.teacherUsageStatistics") }}</p>
      <xm-table
        class="evaluation-table"
        ref="xmTable"
        :form-items="formItems"
        :formOptions="{
          hiddenDefaultFormBtn: true,
        }"
        :columns="columns"
        :fatch-method="fetchMethod"
        :autoLoad="false"
        noEmptyText="el.common.noData"
      >
        <!-- rules: this.formOptions.rules, -->
        <template slot="formBtn">
          <el-button
            type="primary"
            size="small"
            class="teachManage-tool-btnicon"
            @click="query()"
            >{{ $t("el.common.search") }}</el-button
          >
          <el-button
            size="small"
            class="teachManage-tool-btnicon"
            @click="resetForm()"
            >{{ $t("el.common.reset") }}</el-button
          >
          <el-button
            size="small"
            class="teachManage-tool-btnicon"
            type="primary"
            v-btn="'btn-dataOverview-export'"
            @click="handleDerive()"
            >{{ $t("el.common.export") }}</el-button
          >
        </template>
      </xm-table>
      <!-- 弹窗 -->
      <el-dialog
        :title="$t('el.common.reminder')"
        :show-close="false"
        width="500px"
        center
        class="dialog"
        :visible.sync="dialogShow"
      >
        <div class="dialog-content">
          <p>{{ $t("el.planCourse.DataOverviewExportInformation") }}</p>
          <div class="dialog-content-btns f-c">
            <el-button @click="dialogShow = false">{{
              $t("el.schoolResourceManage.shutDown")
            }}</el-button>
            <el-button type="primary" @click="save">{{
              $t("el.planCourse.ToDownload")
            }}</el-button>
          </div>
        </div>
      </el-dialog>
      <import-record-dialog
        :dialogTitle="$t('el.planCourse.importedRecords')"
        :dialogVisible="dialogVisibleImport"
        @handleClose="handleCloseImport"
        v-if="dialogVisibleImport"
        tabType='exportedRecords'
      />
    </div>
  </div>
</template>
<script>
import {
  geSchoolOverviewApi,
  getTeacherApi,
  schoolTeacherExportApi,
} from "@/api/manage/dataOverview.js";
import { startOfDay, endOfDay, subDays, format } from "date-fns";
import ImportRecordDialog from "@/components/biz-components/ImportRecordDialog.vue";
import { handparams } from "../../../../utils";
// import { normalInputValidate } from "@/utils/regex";
import { mapState } from "vuex";
export default {
  name: "regional-data",
  components: {
    ImportRecordDialog,
  },
  data() {
    return {
      activeSchool: "",
      activeSchoolName: "",
      topLoading: false,
      dialogShow: false,
      loading: true,
      options: [],
      dialogVisibleImport: false,
      overviewaaData: {
        schoolCount: 0,
        teacherCount: 0,
        studentCount: 0,
        courseCount: 0,
        studentPersonTime: 0,
        chooseCourseCount: 0,
      },
      overviewaaList: [
        {
          label: "teacherCount",
          title: "使用教师数（个）",
          message: "筛学校内注册的教师账号总和（不包含停用账号）",
          imgUrl: require("@/assets/images/regional/teacherCount.png"),
        },
        {
          label: "studentCount",
          title: "使用学生数（个）",
          message: "学校内已注册“在班”学生的总数",
          imgUrl: require("@/assets/images/regional/student.png"),
        },
        {
          label: "courseCount",
          title: "总开课门数（门）",
          message: "学校内“可正常使用”的总开课门数，相同课程去重",
          imgUrl: require("@/assets/images/regional/courseCount.png"),
        },
        {
          label: "studentPersonTime",
          title: "参与学生人次（人）",
          message:
            "学校内“已报名课程且报名信息已录入系统”的学生数总和，（同一学生报不同课程不去重/相同课程不去重）",
          imgUrl: require("@/assets/images/regional/student.png"),
        },
      ],
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user,
    }),
    formItems() {
      return [
        {
          itemType: "daterange",
          prop: "daterangetime",
          prop1: "startTime",
          prop2: "endTime",
          placeholder: "请选择",
          label: "时间范围",
          clearable: false,
          "value-format": "yyyy-MM-dd HH:mm:ss",
          size: "small",
        },
        {
          itemType: "input",
          prop: "courseName",
          label: "选课名称",
          placeholder: "请输入",
          maxLength: 30,
          clearable: true,
          size: "small",
          itemStyle: {
            width: "150px",
          },
        },
        {
          itemType: "input",
          prop: "teacherName",
          label: "授课老师",
          maxLength: 30,
          placeholder: "请输入",
          clearable: true,
          size: "small",
          itemStyle: {
            width: "150px",
          },
        },
      ];
    },
    columns() {
      return [
        {
          label: "序号",
          prop: "index",
          width: "50px",
          formatter(row, column, cellValue, index) {
            return index + 1;
          },
        },
        {
          label: "授课老师",
          prop: "teacherName",
          width: "115px",
        },
        {
          label: "选课名称",
          prop: "courseName",
          formatter(row, column, cellValue, index) {
            return cellValue ? cellValue : "-";
          },
        },
        {
          label: "课堂人数",
          prop: "classStudentCount",
          "column-key": "classStudentCount",
          "render-header": this.renderHeader,
        },
        {
          label: "老师手机号",
          prop: "teacherPhone",
        },
        {
          label: "备课时长（min）",
          prop: "prepareDuration",
        },
        {
          label: "授课时长（min）",
          prop: "teachDuration",
        },
        {
          label: "总时长（min）",
          prop: "totalDuration",
        },
      ];
    },
    // formOptions() {
    //   return {
    //     // 表单配置
    //     rules: {
    //       courseName: [
    //         {
    //           required: true,
    //           message: "请输入活动名称",
    //           trigger: "blur",
    //         },
    //         {
    //           pattern: normalInputValidate,
    //           message: "活动名称不支持特殊字符",
    //           trigger: "blur",
    //         },
    //       ],
    //     },
    //   };
    // },
  },
  created() {
    this.$nextTick(() => {
      this.$refs["xmTable"].setFormValue("daterangetime", [
        subDays(startOfDay(new Date()), 90),
        endOfDay(subDays(new Date(), 0)),
      ]);
      this.$refs["xmTable"].setFormValue(
        "startTime",
        format(subDays(startOfDay(new Date()), 90), "yyyy-MM-dd HH:mm:ss")
      );
      this.$refs["xmTable"].setFormValue(
        "endTime",
        format(endOfDay(subDays(new Date(), 0)), "yyyy-MM-dd HH:mm:ss")
      );
      this.$refs["xmTable"].query();
    });
    this.getOverview();
    // this.options[0] = {
    //   label: this.userInfo.tenantName,
    //   value: this.userInfo.tenantId,
    // };
    this.activeSchool = this.userInfo.tenantId;
    this.activeSchoolName = this.userInfo.tenantName;
  },
  methods: {
    fetchMethod(query) {
      const params = handparams(query);
      if (params.daterangetime) delete params.daterangetime;
      else {
        params.startTime = format(
          subDays(startOfDay(new Date()), 90),
          "yyyy-MM-dd HH:mm:ss"
        );
        params.endTime = format(
          endOfDay(subDays(new Date(), 0)),
          "yyyy-MM-dd HH:mm:ss"
        );
      }
      params.startTime = Date.parse(new Date(params.startTime).toString());
      params.endTime = Date.parse(new Date(params.endTime).toString());
      params.tenantId = this.userInfo.tenantId;
      return getTeacherApi(params);
    },
    // 导出
    async handleDerive() {
      this.dialogShow = true;
      let params = this.$refs.xmTable.getQueryParams();
      params.startTime = Date.parse(new Date(params.startTime).toString());
      params.endTime = Date.parse(new Date(params.endTime).toString());
      params = {
        ...handparams(params),
        ...{ tenantId: this.activeSchool },
      };
      delete params.daterangetime;
      schoolTeacherExportApi(params).then((res) => {});
    },
    save() {
      this.dialogShow = false;
      this.dialogVisibleImport = true;
    },
    handleCloseImport() {
      console.log("执行了");
      this.dialogVisibleImport = false;
    },
    // 重置
    resetForm() {
      this.$refs["xmTable"].resetForm();
      this.$refs["xmTable"].setFormValue("daterangetime", [
        subDays(startOfDay(new Date()), 90),
        endOfDay(subDays(new Date(), 0)),
      ]);
      this.$refs["xmTable"].setFormValue(
        "startTime",
        format(subDays(startOfDay(new Date()), 90), "yyyy-MM-dd HH:mm:ss")
      );
      this.$refs["xmTable"].setFormValue(
        "endTime",
        format(endOfDay(subDays(new Date(), 0)), "yyyy-MM-dd HH:mm:ss")
      );
    },
    renderHeader(h, { column }) {
      const { label, columnKey } = column;
      const tips = {
        classStudentCount:
          "统计时间范围内：此课程报名学生人数如果筛选时间内此课程老师有多个授课班，课堂人数为多个教学班报名学生数之和（相同学生不去重）",
      };
      return h(
        "div",
        {
          style: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          },
        },
        [
          h("span", null, label),
          h(
            "el-tooltip",
            {
              props: {
                effect: "dark",
                placement: "top",
                content: tips[columnKey],
              },
            },
            [
              h("i", {
                class: "el-icon-question",
                style: { marginLeft: "4px", fontSize: "16px" },
              }),
            ]
          ),
        ]
      );
    },
    getOverview() {
      this.topLoading = true;
      geSchoolOverviewApi(this.userInfo.tenantId)
        .then(({ data }) => {
          this.overviewaaData.schoolCount = data.schoolCount || 0;
          this.overviewaaData.teacherCount = data.teacherCount || 0;
          this.overviewaaData.studentCount = data.studentCount || 0;
          this.overviewaaData.courseCount = data.courseCount || 0;
          this.overviewaaData.studentPersonTime = data.studentPersonTime || 0;
          this.overviewaaData.chooseCourseCount = data.chooseCourseCount || 0;
          this.topLoading = false;
        })
        .finally(() => {
          this.topLoading = false;
        });
    },
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.el-input.is-disabled .el-input__inner {
  background-color: #fff;
}
.dialog-content {
  & > p {
    margin: 20px 0 50px 0;
    text-align: center !important;
  }
  .dialog-content-btns {
    text-align: center;
  }
}
.top-main {
  display: flex;
  justify-content: space-between;
  align-items: center;
  //   margin-bottom: 25px;
}
.regionalSchool {
  padding: 20px 20px 20px;
  background-color: #fff;
  .tip-text {
    font-size: 14px;
    text-align: right;
    font-weight: bold;
  }
  .overview {
    margin-bottom: 20px;
    background-color: #fff;
    box-sizing: border-box;
    & > p {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 10px;
    }
    .overview-list {
      display: flex;
      li {
        max-width: 180px;
        flex: 1;
        //   aspect-ratio: 2.5/1;
        margin-right: 16px;
        min-height: 72px;
        background: #f1f7ff;
        border-radius: 8px;
        box-sizing: border-box;
        padding: 12px 0 0 15px;
        font-weight: bold;
        position: relative;
        .item-top {
          font-size: 24px;
          margin-top: 5px;
        }
        .item-bottom {
          display: flex;
          align-items: center;
          p {
            font-size: 12px;
          }
        }
        .img {
          position: absolute;
          bottom: 0;
          right: 10px;
          width: 42px;
          height: 42px;
        }
      }
    }
  }
}
.main-content {
  background-color: #fff;
  box-sizing: border-box;
  & > p {
    font-weight: bold;
    font-size: 18px;
    margin-bottom: 20px;
  }
}
/deep/ .el-form-item--small {
  margin-bottom: 0px;
}
</style>
