<template>
  <el-dialog
    :before-close="handleClose"
    :visible.sync="dialogVisible"
    width="50%"
    custom-class="dialog-com-import-record"
    append-to-body
  >
    <div slot="title" class="dialog-title">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane
          :label="$t('el.planCourse.importedRecords')"
          name="importedRecords"
        ></el-tab-pane>
        <el-tab-pane
          :label="$t('el.planCourse.exportedRecords')"
          name="exportedRecords"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div v-show="activeName === 'importedRecords'" class="dialog-import-record">
      <p class="import-record-des">
        {{ $t("el.planCourse.deleteRecordTip") }}
      </p>
      <div class="import-record-form">
        <xm-table
          ref="xmTable"
          :fatch-method="fatchMethod"
          :form-items="formItems"
          :columns="columns"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :autoLoad="true"
          :tableOptions="{
            height: tableHeight,
          }"
          noEmptyText="el.common.noData"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              size="small"
              class="teachManage-tool-btnicon"
              @click="query()"
              >{{ $t("el.common.search") }}</el-button
            >
            <el-button
              size="small"
              class="teachManage-tool-btnicon"
              @click="resetForm()"
              >{{ $t("el.common.reset") }}</el-button
            >
          </template>
          <template slot="otherHandle" slot-scope="{ row }">
            <a
              target="_blank"
              :href="row.failAddress"
              style="margin-right: 8px"
            >
              <el-button
                v-if="row.failAddress"
                :loading="row.loading"
                type="text"
              >
                <span class="button-text">{{
                  $t("el.planCourse.DownloadFailedData")
                }}</span>
              </el-button>
            </a>

            <el-button
              :loading="row.loading"
              type="text"
              @click="handleDelete(row)"
              >{{ $t("el.common.delete") }}</el-button
            >
          </template>
        </xm-table>
      </div>
      <div class="import-record-table"></div>
    </div>
    <div v-show="activeName === 'exportedRecords'" class="dialog-import-record">
      <div style="margin-top: 0px" class="import-record-form">
        <xm-table
          ref="xmTable2"
          :fatch-method="fatchMethod2"
          :form-items="formItems2"
          :columns="columns2"
          :formOptions="{
            hiddenDefaultFormBtn: true,
          }"
          :autoLoad="true"
          :tableOptions="{
            height: tableHeight,
          }"
          noEmptyText="el.common.noData"
        >
          <template slot="formBtn">
            <el-button
              type="primary"
              size="small"
              class="teachManage-tool-btnicon"
              @click="query2()"
              >{{ $t("el.common.search") }}</el-button
            >
            <el-button
              size="small"
              class="teachManage-tool-btnicon"
              @click="resetForm2()"
              >{{ $t("el.common.reset") }}</el-button
            >
          </template>
          <template slot="otherHandle2" slot-scope="{ row }">
            <el-button
              v-if="row.taskStatus == 1"
              type="text"
              @click="download(row)"
              >{{ $t("el.planCourse.downloadFile") }}</el-button
            >
          </template>
        </xm-table>
      </div>
      <div class="import-record-table"></div>
    </div>
  </el-dialog>
</template>

<script>
import {
  positionList,
  importRecord,
  deleteRecordById,
  getExportRecordList,
  operationDownloadApi,
} from "@/api/user";
import { handparams } from "@/utils";
import moment from "moment";
export default {
  components: {},
  props: {
    dialogVisible: {
      type: Boolean,
      default: true,
    },
    dialogTitle: {
      type: String,
      default: "",
    },
    tabType: {
      type: String,
      default: "importedRecords",
    },
  },
  data() {
    return {
      activeName: "importedRecords",
      tableHeight: "100%",
      importPositionOptions: [],
    };
  },
  created() {
    this.activeName = this.tabType;
  },
  watch: {
    dialogVisible(newVal) {
      if (newVal) {
        this.query();
        this.query2();
      }
    },
  },
  methods: {
    handleClick(tab, eventl) {
      if (tab.name == "importedRecords") this.resetForm();
      else this.resetForm2();
    },
    handleClose() {
      this.$emit("handleClose");
    },
    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      if (!params.importTime) {
        params.startDate = params.endDate = "";
      } else {
        params.startDate += ":00";
        params.endDate += ":00";
      }
      delete params.importTime;
      let data = handparams(params);
      return importRecord(data)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
    fatchMethod2(params) {
      if (!params.preparationTime) {
        params.startDate = params.endDate = "";
      } else {
        params.startTime = Date.parse(new Date(params.startTime).toString());
        params.endTime = Date.parse(new Date(params.endTime).toString());
      }
      delete params.preparationTime;
      let data = handparams(params);
      return getExportRecordList(data)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },
    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    query2() {
      if (this.$refs["xmTable2"]) {
        this.$refs["xmTable2"].query();
      }
    },
    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },
    resetForm2() {
       this.$refs["xmTable2"].resetForm();
    },
    handleDelete(row) {
      this.$confirm("", this.$t("el.common.deleteConfirm"), {
        center: true,
        customClass: "el-message-box--confirm-right el-message-box--danger",
        message: this.$createElement(
          "div",
          {
            class: "el-message-box--delete",
          },
          this.$t("el.planCourse.deleteRecordConfirm")
        ),
      }).then(() => {
        deleteRecordById(row.id).then(() => {
          this.$message.success(this.$t("el.classManage.deleteSuccess"));
          this.query();
        });
      });
    },
    async init() {
      await this.positionList();
    },
    positionList() {
      this.importPositionOptions = [];
      positionList().then((res) => {
        if (res.data && res.data.length) {
          res.data.forEach((element) => {
            this.importPositionOptions.push({
              label: element.typeName,
              value: element.typeCode,
            });
          });
        }
      });
    },
    // 下载
    async download(row) {
      console.log(row);
      let params = {
        objectName: row.fileUrlObject,
        displayName: row.fileUrlDisplay,
        isDownload: 1,
      };
      operationDownloadApi(params).then(({ data }) => {
        console.log(data, "data");
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = data;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
  },
  async mounted() {
    this.init();
  },
  computed: {
    /**
     * 定义列
     */

    columns() {
      return [
        {
          label: this.$t("el.planCourse.importLocation"),
          prop: "importPosition",
        },
        {
          label: this.$t("el.planCourse.importFile"),
          prop: "importName",
          width: "120px",
        },
        {
          label: this.$t("el.planCourse.importTime"),
          prop: "importDate",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
          width: "150px",
        },
        {
          label: this.$t("el.planCourse.importResult"),
          prop: "importResult",
          width: "150px",
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle",
          width: "170px",
        },
      ];
    },
    columns2() {
      return [
        {
          label: this.$t("el.planCourse.serialNumber"),
          prop: "index",
          width: "70px",
          formatter(row, column, cellValue, index) {
            return index + 1;
          },
        },
        {
          label: this.$t("el.planCourse.fileName"),
          prop: "taskName",
        },
        {
          label: this.$t("el.order.createTime"),
          prop: "crtTime",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
        },
        {
          label: this.$t("el.planCourse.founder"),
          prop: "crtBy",
        },
        {
          label: this.$t("el.planCourse.state"),
          prop: "taskStatus",
          formatter(row, column, cellValue, index) {
            if (cellValue == 0) return "正在导出";
            else if (cellValue == 1) return "导出已完成";
            return "导出失败";
          },
        },
        {
          label: this.$t("el.common.operate"),
          slotName: "otherHandle2",
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "select",
          prop: "importType",
          size: "small",
          options: this.importPositionOptions,
          "popper-append-to-body": false,
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.importLocation"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "importName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.planCourse.importFile"),
          clearable: true,
        },
        {
          itemType: "datetimerange",
          prop: "importTime",
          size: "small",
          prop1: "startDate",
          prop2: "endDate",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd HH:mm",
          "start-placeholder": this.$t("el.common.startTime"),
          "end-placeholder": this.$t("el.common.endTime"),
          format: "yyyy-MM-dd HH:mm",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.planCourse.importTime"),
          clearable: true,
          "time-arrow-control": true,
        },
      ];
    },
    formItems2() {
      return [
        {
          itemType: "daterange",
          prop: "preparationTime",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "start-placeholder": this.$t("el.common.startTime"),
          "end-placeholder": this.$t("el.common.endTime"),
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd HH:mm:ss",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.order.createTime"),
          "append-to-body": false,
          clearable: true,
        },
      ];
    },
  },
};
</script>
<style lang="less">
.dialog-title {
  .el-tabs__nav-wrap::after {
    background-color: #fff !important;
  }
  .el-tabs__item {
    font-weight: bold;
  }
}

.dialog-com-import-record {
  min-width: 730px;
  max-width: 92%;
  max-height: 92%;
  overflow-y: auto;
  .el-dialog__header {
    padding: 20px 28px;
    display: flex;
  }
  .el-dialog__body {
    padding: 0 28px 30px;
    text-align: left;
    .dialog-import-record {
      .import-record-des {
        text-align: left;
      }
    }
    .import-record-form {
      margin-top: 20px;
      width: 100%;
      .xm-table {
        height: calc(50vh - 36px);
      }
      .el-form-item {
        margin-bottom: 8px;
      }
      .el-button {
        border-radius: 5px;
      }
    }
  }
}
</style>
