import axios from "@/plugins/axios.js";

/**
 * 备课列表
 * @return {data}
 */
export function getTableList(data) {
  return axios.get("/prepareCourseLessons/page", {
    params: data,
  });
}
