<template>
  <!-- 备课记录 -->
  <div v-loading="allLoading" class="container-wrap">
    <span class="teachData-main-desc">
      * {{ $t("el.common.dataStatistics") }}</span
    >
    <xm-table
      ref="xmTable"
      :fatch-method="fatchMethod"
      :form-items="formItems"
      :columns="columns"
      :formOptions="{
        hiddenDefaultFormBtn: true,
      }"
      :autoLoad="false"
      :tableOptions="{
        height: tableHeight,
      }"
      @changeQueryParams="changeQueryParams"
      noEmptyText="el.common.noData"
    >
      <template slot="formBtn">
        <el-button
          type="primary"
          size="small"
          class="teachManage-tool-btnicon"
          @click="query()"
          >{{ $t("el.common.search") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          @click="resetForm()"
          >{{ $t("el.common.reset") }}</el-button
        >
        <el-button
          size="small"
          class="teachManage-tool-btnicon"
          type="primary"
          @click="handleExport()"
          >{{ $t("el.common.export") }}</el-button
        >
      </template>
    </xm-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";

import { getTableList } from "@/api/home/prepareRecord";
import { handparams } from "../../../../utils";
import moment from "moment";
export default {
  // 定义数据
  data() {
    return {
      tableHeight: undefined,
      schoolList: [],
      dialogForm: {},
      visible: false,
      canCommit: true,
      allLoading: false,
      classOptions: [], // 授课班级 options
      isSelectDefault: true, // 是否默认选择学校/学部
      nowSchoolCode: "", // 当前学校/学部
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  // 计算属性
  computed: {
    ...mapGetters(["attendSchoolList"]),
    ...mapState({
      userInfo: (state) => state.user,
    }),
    /**
     * 定义列
     */
    columns() {
      let self = this;
      return [
        {
          label: this.$t("el.common.teacherName"),
          prop: "staffName",
          width: "80px",
          fixed: true,
        },
        {
          label: this.$t("el.forgotPwd.MobileNumber"),
          prop: "staffPhone",
          width: "110px",
          fixed: true,
        },
        {
          label: this.$t("el.common.Course"),
          prop: "courseName",
          "min-width": "140px",
        },
        {
          label: this.$t("el.courseDetails.keyPoints"),
          prop: "knowledgeName",
          "min-width": "140px",
        },
        {
          label: this.$t("el.common.startTime"),
          prop: "startTime",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
          width: "160px",
        },
        {
          label: this.$t("el.common.endTime"),
          prop: "endTime",
          formatter(row, column, value) {
            return moment(value).format("yyyy-MM-DD HH:mm:ss");
          },
          width: "160px",
        },
        {
          label: this.$t("el.common.cumulativeDuration"),
          prop: "duration",
          width: "100px",
          formatter(row, column, cellValue) {
            return cellValue ? cellValue + self.$t("el.common.minutes") : "";
          },
        },
      ];
    },
    // 定义查询表单
    formItems() {
      return [
        {
          itemType: "input",
          prop: "staffName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.common.teacherName"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "staffPhone",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.forgotPwd.MobileNumber"),

          clearable: true,
        },
        {
          itemType: "input",
          prop: "courseName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.common.Course"),
          clearable: true,
        },
        {
          itemType: "input",
          prop: "knowledgeName",
          size: "small",
          placeholder: this.$t("el.common.pleaseEnter"),
          label: this.$t("el.courseDetails.keyPoints"),
          clearable: true,
        },
        {
          itemType: "numberRange",
          prop1: "minDuration",
          prop2: "maxDuration",
          prop: "duration",
          size: "small",
          label: this.$t("el.MyHome.todayTimes"),
        },
        {
          itemType: "daterange",
          prop: "courseDateRange",
          size: "small",
          prop1: "startTime",
          prop2: "endTime",
          "popper-append-to-body": false,
          "value-format": "yyyy-MM-dd HH:mm:ss",
          placeholder: this.$t("el.common.pleaseSelect"),
          label: this.$t("el.dataAnalysis.preparingTime"),
          "append-to-body": false,
          clearable: true,
        },
      ];
    },
  },
  watch: {},
  methods: {
    async init() {
      this.query();
    },

    changeQueryParams(newVal, oldVal) {},
    /**
     * 筛选
     */
    query() {
      if (this.$refs["xmTable"]) {
        this.$refs["xmTable"].query();
      }
    },
    /**
     * 导出
     */
    async handleExport() {
      let queryParams = this.$refs.xmTable.getQueryParams(true);
      console.log(queryParams);
      delete queryParams.courseDateRange;
      this.allLoading = true;
      //   if (this.$refs.xmTable.getNowDataList().length > 0) {
      try {
        await this.$utils.exportDownCommon(
          "get",
          handparams(queryParams),
          "/prepareCourseLessons/export",
          `${this.userInfo.tenantName}-备课记录-${new Date().getTime()}.xlsx`
        );
      } catch (error) {
        //
      }
      //   } else {
      //     this.$message.warning(this.$t("el.common.noData"));
      //   }

      this.allLoading = false;
    },

    /**
     * 重置
     */
    resetForm() {
      this.$refs["xmTable"].resetForm();
    },

    resetFormCallback(queryParams) {
      // console.log('queryParams',queryParams);
      // this.setDefaultSchool(queryParams, this.schoolList);
    },

    cancel() {
      this.visible = false;
    },

    /**
     * 获取列表数据的请求方法
     */
    fatchMethod(params) {
      if (params.minDuration > params.maxDuration) {
        this.$message.error(this.$t("el.common.minDurationMustSmaller"));
        this.$refs["xmTable"].resetForm();
        this.$refs["xmTable"].loading = false;
        return;
      }
      if (!params.courseDateRange) {
        params.startTime = params.endTime = "";
      }
      let data = handparams(params);
      delete data.courseDateRange;
      return getTableList(data)
        .then((result) => {
          return result;
        })
        .catch(() => {
          return null;
        });
    },

    /**
     * 窗口变化, tableHeight: 固定表头
     */
    onWindowResize() {
      var height = document.documentElement.clientHeight;
      height -= 380;
      if (height < 280) height = 280;
      this.tableHeight = height + "px";
    },
    // 参数处理
    handparams(param) {
      const params = JSON.parse(JSON.stringify(param));
      for (const i in params) {
        if (params[i] === "") {
          delete params[i];
        }
      }
      // console.log("data===" + JSON.stringify(params))
      return params;
    },
  },
};
</script>

<style lang="less" scoped>
.container-wrap {
  position: relative;
  background: #ffffff;
  padding: 50px 50px 30px;
  border-radius: 10px;

  .teachData-main-desc {
    position: absolute;
    right: 20px;
    top: 20px;
    font-size: 14px;
    color: #807f7f;
  }

  /deep/ .el-form-item {
    margin-bottom: 12px;
  }

  /deep/ .el-button--small,
  .el-button--small.is-round {
    padding: 9px 15px;
    border-radius: 5px;
    font-size: 12px;
    height: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }
}

/deep/ .el-dialog {
  margin-top: 24 px !important;
  margin-bottom: 24 px !important;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
}
</style>
