/**
 * 教务管理-教室管理
 */
import axios from "@/plugins/axios.js";

/**
 * 备受课时长统计概览信息
 * @returns
 */
export function geSchoolOverviewApi(params) {
  return axios.get(`/bsk/duration/statistics/overview/${params}`);
}

/**
 * 备受课时长统计-学校-教师使用统计导出
 */
export function schoolTeacherExportApi(params) {
  return axios.get(`/bsk/duration/statistics/teacher/export`, {
    params,
  });
}

/**
 * 备受课时长统计-教师使用统计
 */
export function getTeacherApi(params) {
  return axios.get(`/bsk/duration/statistics/teacher/page`, {
    params,
  });
}


